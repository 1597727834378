import React, { useState, useEffect } from "react";
import $ from "jquery";
import Layout from "../../layout";
import { Subscription, BreadCrumbBanner } from "../../components/common";
import "react-multi-carousel/lib/styles.css";
import { connect, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import banner_image from "../../images/siteImages/home/demo-contest.png";

const ThailandTourContest = () => {
  const language = useSelector((state) => state.language);
  //const DemoContestData = DemoContestQuery(language);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [objJson, setObjJson] = useState([]);
  const [current_page, setCurrentPage] = useState(1);
  const records_per_page = 10;

  useEffect(() => {
    changePage(1);
    // changePage(0);
  }, []);

  const prevPage = () => {
    let findall_contentDiv = $('.leaderboard__profiles').empty();

    if (current_page > 1) {
      setCurrentPage(current_page - 1);
      changePage(current_page - 1);
    }
  };

  const nextPage = () => {
    let findall_contentDiv = $('.leaderboard__profiles').empty();

    if (current_page < numPages()) {
      setCurrentPage(current_page + 1);
      changePage(current_page + 1);
    }
  };

  const changePage = (page) => {
    let findall_contentDiv = document.querySelector('.leaderboard__profiles');

    fetch('https://metatrader.roxcapitals.com/lead.php')
      .then(response => response.json())
      .then(data => {
        setObjJson(data);
        console.log("change page", data);
        // rest of your code for changing pages...
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        // Handle the error as needed
      });
  };

  const numPages = () => {
    return Math.ceil(objJson.length / records_per_page);
  };

  const handleSearch = () => {
    const name = document.getElementById('search').value.trim();
    let findall_contentDiv = document.querySelector('.leaderboard__profiles').empty();

    if (name !== '') {
      fetch(`https://metatrader.roxcapitals.com/lead.php?name=${name}`)
        .then(response => response.json())
        .then(data => {
          setObjJson(data)
          console.log("handle search", data);
          // Your data processing and UI updates based on the search results
        })
        .catch(error => {
          console.error('Error fetching data search:', error);
          // Handle the error as needed
        });
    } else {
      changePage(current_page);
    }
  };

  return (

    <Layout >
      <BreadCrumbBanner banner_image={banner_image} />
      <section className="sectionbg p-top-10 p-bottom-80 faqs">
        <Container className="">
          <Row>
            <Col md="4">
              <h3>It's Time To Travel</h3>
              <p>Contest Start On </p>
              <p>20 Jan, 2023</p>
            </Col>
            <Col md="8">

            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};
const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(ThailandTourContest);